import React, { useEffect, useState } from "react";
import uniqid from "uniqid";

const Listadonombres = () => {
  const [listado, setlistado] = useState([]);

  const [puntos, setPuntos] = useState(0);
  const [partidos, setPartidos] = useState(0);

  useEffect(() => {
    calcular();
  }, []); //[] sin esto se ejecuta infinitamente

  const premios = [4, 12, 24, 34, 45, 51, 60, 67, 72, 76];

  const calcular = () => {
    setlistado([]);
    var arrayFila = [];
    var nuevaFila = {};
    //if(puntos!==-1 && partidos!==-1){

    for (let i = 0; i <= 9; i++) {
      var wins;
      var loss;
      var puntosFaltantes = premios[i] - puntos;
      console.log("Puntos: " + puntos + "  Partidos: " + partidos);

      if (puntosFaltantes <= 0 || partidos >= puntosFaltantes) {
        wins = "Clasificado";
        loss = "Todos";
      } else {
        if (4 * partidos < puntosFaltantes) {
          //console.log("4 * partidos: "+4 * partidos +" Faltantes:"+puntosFaltantes);
          wins = "Inalcanzable";
          loss = "Inalcanzable";
        } else {
          for (let i = 0; i <= partidos; i++) {
            //Partidos a ganar
            for (let j = 0; j <= partidos - i; j++) {
              //Partidos a perder

              if (4 * i + j >= puntosFaltantes) {
                console.log(
                  "i:" +
                    i +
                    " j:" +
                    j +
                    "  " +
                    parseInt(4 * i + j) +
                    " Faltan: " +
                    puntosFaltantes
                );
                wins = i;
                loss = partidos - i;
                i = partidos;
                break;
              }
            }
          }
        }
      }
      nuevaFila = {
        id: i,
        rank: premios[i],
        ptsFaltantes: premios[i] - puntos,
        partidosNecesarios: wins,
        permitidos: loss,
      };
      //console.log(nuevaFila);

      arrayFila[i] = nuevaFila;
    }

    //setlistado([...listado, nuevaFila]);
    setlistado(arrayFila);

    //}
  };

  return (
    <div>
      <div>
      <div class="colm mx-2 mt-3">
      <div className="card text-center text-white bg-dark mb-3">

          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              Puntos ganados
            </span>
            <input
              onChange={(e) => {
                setPuntos(e.target.value);
              }}
              id="rangePuntos"
              type="number"
              class="form-control"
              min="1"
              max="80"
              placeholder="Ingresar los puntos ganados"
              aria-label="Username"
              aria-describedby="basic-addon1"
            ></input>
          </div>

          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              Partidos restantes
            </span>
            <input
              id="rangePartidos"
              type="number"
              class="form-control"
              min="0"
              max="20"
              placeholder="Ingresar los partidos restantes"
              aria-label="Username"
              aria-describedby="basic-addon1"
              onChange={(e) => {
                setPartidos(e.target.value);
              }}
            ></input>
</div>
  

            <input
              type="button"
              value="Calcular"
              className="btn btn-secondary"
              onClick={(e) => {
                calcular();
              }}
            />
  
          </div>
          <table className="table table-dark table-striped">
            <thead>
              <tr>
                <th scope="col">Rank</th>
                <th scope="col">Pts</th>
                <th scope="col">Faltan</th>
                <th scope="col">Partidos</th>
                <th scope="col">Podes perder</th>
              </tr>
            </thead>
            <tbody>
              {listado.map((item) => (
                <tr>
                  <td>{10-item.id }</td>
                  <td>{item.rank}</td>
                  <td>{item.ptsFaltantes}</td>
                  <td>{item.partidosNecesarios}</td>
                  <td>{item.permitidos}</td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>


          <div class="card">
  <div class="card-body">
    <h3>Informacion</h3>

    <h5>Modo de uso:</h5>
    <p>Completar el campo "Puntos ganados" con la cantidad de puntos adquiridos hasta el momento</p>
    <p>Completar el campo "Partidos restantes" con la cantidad de que te quedan por jugar</p>
    <p>Pulsar el boton "Calcular"</p>


    <h5>Columnas:</h5>
    <ul>
  <li>Rank: Nivel de recompenza.</li>
  <li>Pts: Puntos necesarios para obtener el nivel de recompenza.</li>
  <li>Faltan: Puntos faltantes para conseguir el niver de recompenza.</li>
  <li>Partidos: Numero minimo de partidos a ganar.
  <ul>
    <li>Clasificado: Se mostrara si aun perdiendo los partidos restantes ya se obtienen los puntos necesarios.</li>
    <li>Inalcanzable: Se mostrara si aun ganando los partidos restantes no se acumulan los puntos suficientes.</li>
  </ul>
  </li>
  <li>Podes perder: Numero de partidos que podes perder y ganar la recompenza igualmente.
  <ul>
    <li>Todos: Podes perder todos los partidos restantes.</li>
    <li>Inalcanzable: Se mostrara si aun ganando los partidos restantes no se acumulan los puntos suficientes.</li>
  </ul>
  </li>
    </ul>


  </div>
</div>




      </div>



    </div>

  );
};

export default Listadonombres;
