import React from "react";

const Nav = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
      <span class="navbar-brand mb-0 h1">FUT Champions calculator</span>
        <a className="navbar-brand" href="localhost:3000">
          <img 
            src="/farmasnbot.png"
            className="d-inline-block align-text-top "
            height="30"
            alt=""
            loading="lazy"
          />
           
        </a>
      </div>
    </nav>
  );
};

export default Nav;
