import Nav from './components/Nav';
import Listadonombres from './components/Listadonombres.jsx';
import Footer from './components/Footer.jsx';

function App() {
  return (
    <div className="App">
      <div>
        <Nav></Nav>
        <Listadonombres></Listadonombres>
        <Footer></Footer>

      </div>
    </div>
  );
}

export default App;
