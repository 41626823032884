import React from "react";

const Footer = () => {
  return (
    <footer className="page-footer font-small white">
  <div className="footer-copyright text-center py-3 text-light">Creado por:
    <a href="mailto: agustinbenegas@gmail.com"> Benegas Agustin</a>
  </div>
</footer>
  );
};

export default Footer;